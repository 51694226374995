var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"},[_c('vx-card',[_c('div',{staticClass:"full-page-bg-color",attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-full md:w-full mx-auto self-center d-theme-dark-bg"},[_c('div',{staticClass:"p-8"},[_c('div',{staticClass:"vx-card__title mb-4"},[_c('h4',{staticClass:"mb-4"},[_vm._v("Change Password")]),_c('p',[_vm._v("Please enter your new password.")]),_c('div',{staticClass:"mt-2"},[(this.changeError === false)?_c('vs-alert',{attrs:{"id":"alert","active":_vm.showDismissibleAlert,"closable":"","icon-pack":"feather","close-icon":"icon-x","color":"success"},on:{"update:active":function($event){_vm.showDismissibleAlert=$event}}},[_vm._v(_vm._s(_vm.message))]):_c('vs-alert',{attrs:{"active":_vm.showDismissibleAlert,"closable":"","icon-pack":"feather","close-icon":"icon-x","color":"danger"},on:{"update:active":function($event){_vm.showDismissibleAlert=$event}}},[_vm._v(_vm._s(_vm.message))])],1)]),_c('form',{attrs:{"autocomplete":"off"}},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full mb-5",attrs:{"success":!_vm.errors.first('currentPassword') &&
                    _vm.user.currentPassword != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('currentPassword') ? true : false,"name":"currentPassword","danger-text":_vm.errors.first('currentPassword'),"label":"Current Password","icon-no-border":"","icon-pack":"feather","autocomplete":"new-password","icon":_vm.currentPasswordIcon,"type":_vm.currentPasswordType},nativeOn:{"click":function($event){return _vm.showHideCurrentPassword($event)}},model:{value:(_vm.user.currentPassword),callback:function ($$v) {_vm.$set(_vm.user, "currentPassword", $$v)},expression:"user.currentPassword"}}),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                    required: true,
                    min: 8,
                    regex: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                  }),expression:"{\n                    required: true,\n                    min: 8,\n                    regex: /^.*(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\\W]+$)/,\n                  }"}],ref:"password",staticClass:"w-full mb-5",attrs:{"success":!_vm.errors.first('newPassword') && _vm.user.newPassword != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('newPassword') ? true : false,"name":"newPassword","danger-text":_vm.errors.first('newPassword'),"label":"New Password","data-vv-as":"new password","icon-no-border":"","icon-pack":"feather","autocomplete":"new-password","icon":_vm.passwordIcon,"type":_vm.passwordType},nativeOn:{"click":function($event){return _vm.showHidePassword($event)}},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}}),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:password'),expression:"'required|confirmed:password'"}],staticClass:"w-full mb-5",attrs:{"success":!_vm.errors.first('confirmNewPassword') &&
                    _vm.user.confirmNewPassword != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('confirmNewPassword') ? true : false,"name":"confirmNewPassword","data-vv-as":"confirm password","danger-text":_vm.errors.first('confirmNewPassword'),"label":"Confirm Password","icon-no-border":"","icon-pack":"feather","autocomplete":"new-password","icon":_vm.confirmPasswordIcon,"type":_vm.confirmPasswordType},nativeOn:{"click":function($event){return _vm.showHideConfirmPassword($event)}},model:{value:(_vm.user.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmNewPassword", $$v)},expression:"user.confirmNewPassword"}})],1),_c('div',{staticClass:"flex flex-wrap justify-between flex-col-reverse sm:flex-row"},[_c('vs-button',{staticClass:"w-full sm:w-auto",attrs:{"color":"success","disabled":!_vm.validateForm},on:{"click":_vm.changePassword}},[_vm._v("Save Changes")]),_c('vs-button',{staticClass:"w-full sm:w-auto",attrs:{"color":"danger"},on:{"click":_vm.cancelChangePassword}},[_vm._v("Cancel")])],1)])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }